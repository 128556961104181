/**
 * Palfinger.PalShow.WebAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ECablePositionOnWinch } from './eCablePositionOnWinch';
import { IGeometryAssembly } from './iGeometryAssembly';
import { ESupportMountingType } from './eSupportMountingType';


export interface CraneConfigurationSimple { 
    
    m_tLoaderCraneFamily?: string | null;
    
    m_tLoaderCraneName?: string | null;
    
    m_tMountingbase?: string | null;
    
    m_atMountingbaseAccessoryNames?: Array<string> | null;
    
    m_atCraneAccessoryNames?: Array<string> | null;
    
    _craneMfaAccessoryNames?: Array<string> | null;
    
    m_fEquipmentConditionalLoad?: number;
    
    m_tFlyjibName?: string | null;
    
    m_atFlyjibAccessoryNames?: Array<string> | null;
    
    m_eSupportMountingType?: ESupportMountingType;
    
    m_nMainBoomManualExtensions?: number;
    
    m_nKnuckleBoomManualExtensions?: number;
    
    m_nFlyjibManualExtensions?: number;
    
    m_iPullInPartLoadpoint?: number;
    
    m_iMainStrokeMeasurement?: number;
    
    m_iKnuckleStrokeMeasurement?: number;
    
    m_iFlyjibStrokeMeasurement?: number;
    
    m_fMountingHeight?: number;
    
    m_iDps?: number;
    
    m_fMainReductionFactor?: number;
    
    m_fKnuckleReductionFactor?: number;
    
    m_fFlyjibReductionFactor?: number;
    
    m_fMainExtensionReductionFactor?: number;
    
    m_fKnuckleExtensionReductionFactor?: number;
    
    m_fFlyjibExtensionReductionFactor?: number;
    
    m_fCableForceReductionFactor?: number;
    
    m_fSupportForceReductionFactor?: number;
    
    m_fSlewingMomentBruttoReductionFactor?: number;
    
    m_fSlewingSlopeAngleReductionFactor?: number;
    
    m_fSlewingRotationSpeedReductionFactor?: number;
    
    m_fSlewingRotationAccelerationTimeReductionFactor?: number;
    
    m_fSlewingRotationDecelerationTimeReductionFactor?: number;
    
    m_fPressureTableReductionFactor?: number;
    
    m_bUsePressureTableForKnuckleCylinder?: boolean;
    
    m_iPressureTable?: number;
    
    m_tPressureTableName?: string | null;
    
    m_iDisplacementTable?: number;
    
    m_iMainJointTable?: number;
    
    m_iKnuckleJointTable?: number;
    
    m_iFlyjibJointTable?: number;
    
    m_fCableLengthTotal?: number;
    
    m_fCableDistanceLoad?: number;
    
    m_eCablePositionOnWinch?: ECablePositionOnWinch;
    
    m_nCableStrings?: number;
    
    m_iActualLoadPointID?: number;
    
    m_oAdditionalLoadPointAssembly?: IGeometryAssembly;
    
    additionalProperties?: { [key: string]: any | undefined; } | null;
}

export class CraneDataRequestDto {
    craneConfiguration: CraneConfigurationSimple | undefined;
    workingPressure: number;

    constructor(
      workingPressure: number,
      craneConfiguration: CraneConfigurationSimple | undefined
    ) {
      this.craneConfiguration = craneConfiguration;
      this.workingPressure = workingPressure;
    }
}
  